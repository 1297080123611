<template>
  <div class="content-right rounded-3">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'dashboard' }">Dashboard</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'users' }">Users</router-link>
        </li>
        <li class="breadcrumb-item active">
          <router-link :to="{ name: 'roles' }">Roles</router-link>
        </li>
        <li class="breadcrumb-item active">Edit Permissions</li>
      </ol>
    </nav>
    <div
      class="content-body"
      id="fullHeight"
      style="min-height: calc(100vh - 135px)"
    >
      <div class="row">
        <div class="col-md-11">
          <h2 class="title2">Edit Permissions</h2>
        </div>
        <div class="col-md-1 text-right">
          <router-link :to="{ name: 'roles' }" class="btn btn-outline-primary"
            >Back</router-link
          >
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="card">
            <div class="card-header">
              <flash-message class="myCustomClass"></flash-message>
            </div>
            <div class="card-body">
              <div v-if="this.$store.state.loader">
                <loader
                  object="#f74b3d"
                  color1="#ffffff"
                  color2="#17fd3d"
                  size="5"
                  speed="2"
                  bg="#343a40"
                  objectbg="#999793"
                  opacity="80"
                  disableScrolling="false"
                  name="spinning"
                ></loader>
              </div>
              <form v-else @submit.prevent="onSubmit">
                <div v-if="role_id != '7'">
                  <span v-if="$store.state.permittedActions.includes(100)">
                  <h3>Dashboard</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox" v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="1"
                        class="slider round"
                        id="dashboard"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="dashboard">Open Dashboard Page</label>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(200)">
                  <h3>Employees</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="2"
                        id="emp-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="emp-page">Open Employee Page</label>

                      <div class="container">
                        <div>
                         
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="22"
                            id="emp-profile"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="emp-profile">View Employee Profile</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="21"
                            id="add-emp"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-emp">Add Employee</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="23"
                            id="edit-emp"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="edit-emp">Edit Employee</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="24"
                            id="delete-emp"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-emp">Delete Employee</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="25"
                            id="archive-emp"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="archive-emp">Archive Employee</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(300)">
                  <h3>Attendances</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="3"
                        id="attendances-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="attendances-page"
                        >Open Attendances Page</label
                      >

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="31"
                            id="edit-attendances"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="edit-attendances">Edit Attendance</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="32"
                            id="delete-attendances"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-attendances"
                            >Delete Attendances</label
                          >
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="33"
                            id="add-attendances"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-attendances">Add Attendance</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(400)">
                  <h3>Schedules</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="4"
                        id="schedules-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="schedules-page">Open Schedules Page</label>

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="41"
                            id="add-schedules"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-schedules">Add Schedules</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="42"
                            id="edit-schedules"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="edit-schedules">Edit Schedules</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="43"
                            id="delete-schedules"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-schedules">Delete Schedules</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="44"
                            id="archive-schedules"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="archive-schedules"
                            >Archive Schedules</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(500)">
                  <h3>Leaves</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="5"
                        id="leaves-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="leaves-page">Open Leaves Page</label>

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="52"
                            id="edit-leaves"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="edit-leaves">Edit Leaves</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="53"
                            id="delete-leaves"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-leaves">Delete Leaves</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(900)">
                  <h3>Settings</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="9"
                        id="settings-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="settings-page">Open Settings Page</label>

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="91"
                            id="delete-settings"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-settings">Update Settings</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(700)">
                  <h3>Reports</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="7"
                        id="report-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="report-page">Open Reports Page</label>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(800)">
                  <h3>Users</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="8"
                        id="users-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="users-page">Open Users Page</label>

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="81"
                            id="add-users"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-users">Add Users</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="82"
                            id="edit-users"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="edit-users">Edit Users</label>
                        </div>
                        <div>
                           <label class="switch">
                              <input type="checkbox"
                              v-model="$v.form.perms.$model"
                              :validator="$v.form.perms"
                              value="83"
                              id="delete-users"
                              class="slider round"
                              >
                              <span class="slider round"></span>
                            </label>
                          <label for="delete-users">Delete Users</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(1000)">
                  <h3>User Roles</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="10"
                        id="role-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="role-page">Open User Roles Page</label>

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="101"
                            id="add-role"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-role">Add User Roles</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="102"
                            id="edit-role"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="edit-role">Edit User Roles</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="103"
                            id="permission"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="permission">Set Permissions</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="104"
                            id="delete-role"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-role">Delete User Roles</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(1100)">
                  <h3>Companies</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="11"
                        id="companies-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="companies-page">Open Companies Page</label>

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="111"
                            id="add-companies"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-companies">Add Companies</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="113"
                            id="edit-companies"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="edit-companies">Edit Companies</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="112"
                            id="delete-companies"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-companies">Delete Companies</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(1200)">
                  <h3>Departments</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="12"
                        id="dpt-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="dpt-page">Open Departments Page</label>

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="121"
                            id="add-dpt"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-dpt">Add Departments</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="122"
                            id="delete-dpt"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-dpt">Delete Departments</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(1300)">
                  <h3>Job Titles</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="13"
                        id="job-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="job-page">Open Job Titles Page</label>

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="131"
                            id="add-job"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-job">Add Job Titles</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="132"
                            id="delete-job"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-job">Delete Job Titles</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(1400)">
                  <h3>Leave Types</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="14"
                        id="leave-type-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="leave-type-page">Open Leave Types Page</label>

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="141"
                            id="add-leave-type"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-leave-type">Add Leave Type</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="142"
                            id="delete-leave-type"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-leave-type"
                            >Delete Leave Type</label
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(1500)">
                  <h3>Leave Groups</h3>
                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="15"
                        id="leave-page"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="leave-page">Open Leave Groups Page</label>

                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="151"
                            id="add-leave"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-leave">Add Leave Group</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="152"
                            id="edit-leave"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="edit-leave">Edit Leave Group</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="153"
                            id="delete-leave"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-leave">Delete Leave Group</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <span v-if="$store.state.permittedActions.includes(1600)">
                  <h3>Accessories Management</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="16"
                        id="accessories-management"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="accessories-management">Open Accessories Management</label>
                    </div>
                  </div>
                  <hr />
                  </span>
                  <div class="group" v-if="currentRole =='6'">
                  <h2>Permissons Groups</h2>
                  <hr />
                  <div class="custom-wrapper-box">
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="100"
                            id="dashboard-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="dashboard-group">Can access Dashboard permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="200"
                            id="employee-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="employee-group">Can access Employee permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="300"
                            id="attendance-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="attendance-group">Can access Attendance permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="400"
                            id="schedule-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="schedule-group">Can access Schedules permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="500"
                            id="leaves-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="leaves-group">Can access Leaves permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="900"
                            id="settings-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="settings-group">Can access Settings permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="700"
                            id="report-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="report-group">Can access Report permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="800"
                            id="users-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="users-group">Can access Users permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="1000"
                            id="user-roles-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="user-roles-group">Can access User Roles permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="1100"
                            id="companies-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="companies-group">Can access Companies permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="1200"
                            id="department-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="department-group">Can access Departments permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="1300"
                            id="job-title-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="job-title-group">Can access Job Title permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="1400"
                            id="leave-type-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="leave-type-group">Can access Leave Type permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="1500"
                            id="leave-group-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="leave-group-group">Can access Leave Group permissions group</label>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-6">
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="1600"
                            id="accessories-management-group"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="accessories-management-group">Can access Accessories Management permissions group</label>
                        </div>
                      </div>
                    </div>
                </div>
                </div>
                <div v-else>
                  <h3>Leaves</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="55"
                        id="my-leave"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="my-leave">Open My leaves Page</label>
                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="551"
                            id="edit-my-leave"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="edit-my-leave">Draft Leave</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="552"
                            id="delete-leave"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-leave">Delete Leave</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="553"
                            id="cancel-leave"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="cancel-leave">Cancel Leave</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h3>Schedules</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="441"
                        id="open-schedule"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="open-schedule">Open My Schedules</label>
                      <div class="container">
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="442"
                            id="edit-my-schdule"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="edit-my-schdule">Edit Schedules</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="443"
                            id="delete-schdule"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="delete-schdule">Delete Schedule</label>
                        </div>
                        <div>
                          <label class="switch">
                            <input type="checkbox"
                            v-model="$v.form.perms.$model"
                            :validator="$v.form.perms"
                            value="444"
                            id="add-my-schdule"
                            class="slider round"
                            >
                            <span class="slider round"></span>
                          </label>
                          <label for="add-my-schdule">Add Schedules</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <h3>Settings</h3>

                  <div class="row">
                    <div class="col-md-6">
                      <label class="switch">
                        <input type="checkbox"
                        v-model="$v.form.perms.$model"
                        :validator="$v.form.perms"
                        value="1"
                        id="dashboard"
                        class="slider round"
                        >
                        <span class="slider round"></span>
                      </label>
                      <label for="dashboard">Open Setting Page</label>
                    </div>
                  </div>
                  <hr />
                  
                </div>
                <div class="text-right">
                  <button type="submit" class="btn btn-outline-primary space">
                    Save
                  </button>
                  <router-link
                    :to="{ name: 'roles' }"
                    class="btn btn-outline-primary"
                    >Cancel</router-link
                  >
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import axios from "axios";
require("../../axios.js");

export default {
  data() {
    return {
      form: {
        perms: this.formData,
        role_id: null,
      },
      currentRole: localStorage.getItem('role_id'),
      loaded: false,
      role_id: this.$route.params.id,
    };
  },
  validations: {
    form: {
      perms: {
        required,
      },
      role_id: {},
    },
  },
  created() {
    /* this.videos = this.getFreeVideo */
    this.$store.dispatch("switchLoader", true);
    this.$store.dispatch("loadRolePermissions", this.$route.params.id);
  },
  mounted() {
    this.form.role_id = this.$route.params.id;
    this.form.perms = this.$store.state.permissions;
    var v = this;
    setTimeout(function () {
      v.form.perms = v.$store.state.permissions;
    }, 1000);
  },
  updated() {
  },
  methods: {
    onSubmit() {
      axios
        .post("users/roles/permissions/update", this.form)
        .then(({ data }) => {
          if (data.success) {
            this.$store.dispatch("loadPermissions");
            this.flashMessage.success({
              message: data.success,
              time: 3000,
            });
            this.$store.dispatch("loadRolePermissions", this.$route.params.id);
            this.$router.push({ name: "roles" });
          } else {
            this.flashMessage.error({
              message: data.data.error,
              time: 3000,
            });
          }
        });
    },
  },
};
</script>
